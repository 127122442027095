import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import Loader from "../../../components/loader";
import { Dialog } from "primereact/dialog";
import apiClient from "../../../../services/axios_api";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";

const getCurrentMonthDates = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();

  const start = new Date(Date.UTC(year, month, 1));
  const end = new Date(Date.UTC(year, month + 1, 0));

  return {
    start: start.toISOString().split("T")[0],
    end: end.toISOString().split("T")[0],
  };
};

const Attendus = () => {
  const [dialog, setDialog] = useState(false);
  const [gateList, setGateList] = useState([]);
  const [attendusList, setAttendusList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowselect, setRowselect] = useState(null);
  const [attendanceDetails, setAttendanceDetails] = useState([]);

  const { start: defaultStartDate, end: defaultEndDate } =
    getCurrentMonthDates();

  const formik = useFormik({
    initialValues: {
      searchText: "",
      gateId: null,
      groupId: null,
      from: defaultStartDate,
      to: defaultEndDate,
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setLoading(true);

        const response = await apiClient.post("/Attendances", values);
        const data = response.data;
        setAttendusList(data?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  const GetGates = async (rowData) => {
    const userId = JSON.parse(localStorage.getItem("user"))?.id;
    try {
      const response = await apiClient.post("/Gates/list", {
        loggedInUserId: userId,
      });
      setGateList(response?.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    formik.handleSubmit();
    GetGates();
  }, []);

  const onHide = () => {
    setDialog(false);
    setAttendanceDetails([]);
  };

  const actionTemplate = (rowData) => (
    <div>
      <Button
        icon="pi pi-ellipsis-v"
        className="custom-btn-edit p-0"
        onClick={() => {
          setAttendanceDetails(rowData.details);
          setDialog(true);
        }}
      />
    </div>
  );

  const rowClass = (data) => {
    return {
      "check-in-row": data.status === "Check-In",
      "check-out-row": data.status === "Check-Out",
    };
  };

  const exportCSV = () => {
    if (attendusList.length === 0) {
      toast.warning("No data to export");
      return;
    }
  
    const exportData = attendusList.map((item) => ({
      "Person Name": item.personName,
      "Mobile Number": item.personMobileNumber,
      "CNIC": item.personCnic,
      "Gate Number": item.gateNumber,
      "Gate Keeper Name": item.gateKeeperName,
      "Checked In": item.checkedInFormatted,
      "Checked Out": item.checkedOutFormatted || "-",
    }));
  
    const worksheet = XLSX.utils.json_to_sheet(exportData);
  
    worksheet["!cols"] = Array(Object.keys(exportData[0]).length).fill({ wch: 30 });
  
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Attendance Data");
  
    XLSX.writeFile(workbook, "AttendanceData.xlsx");
  };
  
  
  

  return (
    <>
      <Dialog
        visible={dialog}
        onHide={onHide}
        header={"Attendance Log"}
        className="dialog-size"
      >
        {attendanceDetails.length > 0 ? (
          <div>
            <DataTable
              value={attendanceDetails}
              paginator
              rows={5}
              responsiveLayout="scroll"
              rowClassName={rowClass}
              paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              className="custom-data-table"
            >
              <Column
                field="status"
                header="Status"
                body={(rowData) => (
                  <span
                    style={{
                      color: rowData.status === "Check-In" ? "green" : "red",
                      fontWeight: "bold",
                    }}
                  >
                    {rowData.status}
                  </span>
                )}
              />
              <Column
                field="dateFormatted"
                header="Date"
                body={(rowData) => rowData.dateFormatted}
              />
            </DataTable>
          </div>
        ) : (
          <p>No data found.</p>
        )}
      </Dialog>

      <form onSubmit={formik.handleSubmit}>
        <div className="card pb-3">
          <div className="p-fluid formgrid grid">
            <div className="field md:col-2">
              <label htmlFor="searchText" style={{ fontWeight: "bold" }}>
                Search
              </label>
              <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
                  id="searchText"
                  name="searchText"
                  placeholder="Search by Mobile number"
                  type="text"
                  value={formik.values.searchText}
                  onChange={formik.handleChange}
                />
              </span>
              {formik.touched.searchText && formik.errors.searchText ? (
                <div className="error">{formik.errors.searchText}</div>
              ) : null}
            </div>
            <div className="field md:col-2">
              <label className="mb-2" style={{ fontWeight: "bold" }}>
                Gate
              </label>
              <Dropdown
                value={formik.values.gateId}
                optionLabel="number"
                name="gateId"
                options={gateList}
                optionValue="id"
                placeholder="Select Gate"
                filter
                onChange={formik.handleChange}
              />
              {formik.touched.gateId && formik.errors.gateId ? (
                <div className="error">{formik.errors.gateId}</div>
              ) : null}
            </div>
            <div className="field md:col-2">
              <label htmlFor="from" style={{ fontWeight: "bold" }}>
                From Date
              </label>
              <InputText
                id="from"
                name="from"
                type="date"
                value={formik.values.from}
                onChange={formik.handleChange}
              />
              {formik.touched.from && formik.errors.from ? (
                <div className="error">{formik.errors.from}</div>
              ) : null}
            </div>
            <div className="field md:col-2">
              <label htmlFor="to" style={{ fontWeight: "bold" }}>
                To Date
              </label>
              <InputText
                id="to"
                name="to"
                type="date"
                value={formik.values.to}
                onChange={formik.handleChange}
              />
              {formik.touched.to && formik.errors.to ? (
                <div className="error">{formik.errors.to}</div>
              ) : null}
            </div>
            <div className="md:col-10"></div>
            <div className="md:col-2">
              <Button className="custom-btn w80" label="Search" type="submit" />
            </div>
          </div>
        </div>
        <div className="card">

          <div className="grid">
            <div className="d-flex justify-content-end mb-2 w-100">

            <Button
                        className="custom-btn export-excel"
                        label="Export to Excel"
                        icon="pi pi-window-maximize"
                        onClick={exportCSV}
                      />
            </div>
            
            <div className="md:col-12">
              {loading && <Loader />}
              <DataTable
                value={attendusList}
                scrollable
                scrollHeight="500px"
                paginator
                rows={10}
                paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                className="custom-data-table"
                globalFilterFields={["name", "cnic", "mobile"]}
              >
                <Column field="personName" header="Person Name" />
                <Column field="personMobileNumber" header="Mobile Number" />
                <Column field="personCnic" header="CNIC" />
                <Column field="gateNumber" header="Gate Number" />
                <Column field="gateKeeperName" header="Gate Keeper Name" />
                <Column
                  field="checkedInFormatted"
                  header="Checked In"
                />
                <Column
                  field="checkedOutFormatted"
                  header="Checked Out"
                  body={({ checkedOutFormatted }) =>
                    checkedOutFormatted ? checkedOutFormatted : "-"
                  }
                />
                <Column header="Action" body={actionTemplate} />
              </DataTable>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Attendus;
